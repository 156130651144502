<template>
  <nuxt />
</template>

<script>
import { mapActions } from 'vuex';
import { addAdmitadScript, addDeduplicationScript } from '~/utils/addAdmitadScript';
import { addAdvCakePage } from '~/utils/advCake';
import { saveUtm } from '~/utils/routeUtm';

export default {
  name: 'EmptyLayout',

  mounted() {
    addDeduplicationScript();
    addAdmitadScript();

    saveUtm(this.$route);

    addAdvCakePage(this.$route);

    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowSize);
  },

  methods: {
    ...mapActions({
      setWindowSize: 'setWindowSize',
    }),
  },
};
</script>

<style scoped lang="scss">

// артефакты от старых страниц
::v-deep section {
  margin-bottom: 0;

  h2,
  h3 {
    margin: 0;
  }
}

</style>
