<template>
  <div class="v1-layout">
    <notifications position="top right" />
    <modal-container />

    <div
      ref="wrapper"
      class="content-wrapper"
    >
      <page-bottom-banner />
      <common-modals />

      <app-header />
      <app-header-modal v-if="currentModal" />

      <div class="main-content">
        <nuxt />

        <synergy-friends />
        <app-footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { addAdmitadScript, addDeduplicationScript } from '~/utils/addAdmitadScript';
import { addAdvCakePage } from '~/utils/advCake';
import { saveUtm } from '~/utils/routeUtm';

import ModalContainer from '@/components/popups/ModalContainer.vue';
import AppFooter from '@/components/v2/common/AppFooter.vue';
import PageBottomBanner from '@/components/school/PageBottomBanner.vue';
import CommonModals from '@/components/v2/modals/CommonModals.vue';
import AppHeader from '~/components/v2/common/AppHeader/AppHeader.vue';
import AppHeaderModal from '~/components/v2/common/AppHeader/AppHeaderModal.vue';
import SynergyFriends from '~/components/v2/common/SynergyFriends.vue';

export default {
  name: 'LayoutDefault',
  components: {
    SynergyFriends,
    AppHeaderModal,
    AppHeader,
    CommonModals,
    PageBottomBanner,
    ModalContainer,
    AppFooter,
  },
  head() {
    return {
      link: [
        { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true },
        { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('appHeaderModals', ['currentModal']),
    phone() {
      return process.env.phone;
    },
  },

  mounted() {
    addDeduplicationScript();
    addAdmitadScript();

    addAdvCakePage(this.$route);

    saveUtm(this.$route);

    window.addEventListener('resize', this.setWindowSize);
    this.setWindowSize({ target: window });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setWindowSize);
  },
  methods: {
    ...mapActions({
      setWindowSize: 'setWindowSize',
    }),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .page-bottom-banner {
  z-index: 99;
}

::v-deep .page-bottom-banner ~ .main-content {
  padding-bottom: 100px;

  @include media-down(tablet) {
    padding-bottom: 80px;
  }
}

.content-wrapper {
  background-color: #ECEDF0;
}

.main-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--scale) * 110);
  overflow-x: hidden;
  padding-top: calc(var(--scale) * 110);

  @include media-down($size-tablet) {
    padding-top: calc(var(--scale) * 100);
  }

  & > div {
    flex-grow: 1;
  }
}

.v1-layout {
  &:has(.dropdown-menu__mobile) {
    @include media-down($size-tablet) {
      .page-bottom-banner {
        display: none;
      }

      ::v-deep .header {
        top: 0 !important;
      }
    }
  }

  ::v-deep .page-bottom-banner {
    & ~ .header__wrapper header,
    & ~ .header {
      @include media-down($size-tablet) {
        top: calc(var(--scale) * 50);

        .header__inner {
          top: 0;
        }
      }
    }

    & ~ .main-content {
      @include media-down($size-tablet) {
        margin-top: calc(var(--scale) * 50);
      }
    }

    & ~ .footer {
      padding-bottom: calc(var(--scale) * 100);
    }
  }

  .footer {
    @include media-down($size-tablet) {
      padding-bottom: calc(var(--scale) * 80) !important;
    }
  }
}

 ::v-deep .notification-title {
   margin-bottom: 0.75em;
 }

</style>
